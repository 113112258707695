<template>
  <div class="hello">
    <h1>개인정보처리방침</h1>
    <div class="corner">
      <h2>개인정보의 처리</h2>
      <div class="content">
        '기기 충전시 열기' 은 개인정보를 수집하지 않습니다
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.corner {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
}

.content {
  padding: 0 0 0 10px;
  margin: 0 0;
  text-align: left;
}

div {
  margin: 10px 10px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 15px;
}

h3 {
  text-align: center;
  margin: 3px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
